
import { IonGrid, IonCol, IonRow, IonSearchbar, IonCard, IonCardContent, IonToolbar, alertController, IonItem } from "@ionic/vue";
import { selectClientes } from "@/graphql/queries/selectClientes";
import RowHeaderPeticiones from "./RowHeaderPeticiones.vue";
import { TokenService } from "@/services/token.service";
import { AuthService } from "@/services/auth.service";

export default {
  name: "Peticion",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    RowHeaderPeticiones,
    IonCard,
    IonCardContent,
    IonSearchbar,
    IonToolbar,
    IonItem
  },
  data() {
    return {
      elBusq: null,
      cargar: false,
      token: null,
      id: null,
      clientes: null,
      operarioIbancarId: TokenService.getId(), // Seteamos el id del operario ibancar que tienes permisos administrador y se lo pasamos mediante una prop al componente que contiene boton de < volver para que el retorno sea al operario de ibancar y no al cliente
      operarioIbancarToken: TokenService.getToken() // Seteamos el token del operario ibancar que tienes permiso administrado y se lo pasamos mediante una prop al componente que contiene el boton < volver para que le retorno seal al operario de ibancar y no al cliente
    };
  },
  mounted() {
    this.id = TokenService.getId();
    this.token =TokenService.getToken();
    if (TokenService.getAdmin() == "0") {
      window.location.href = "home";
    } else this.check();
  },
  apollo: {
    busqueda() {
      return {
        query: selectClientes,
        variables() {
          return {
            id: parseInt(this.id),
            term: this.elBusq,
            token: this.token
          };
        },
        fetchPolicy: "Only-network",
        update: (data) => this.clientes = data.listCliente,
      };
    },
  },
  methods: {
    BusquedaClientes: function () {
      this.$apollo.queries.busqueda.fetchMore({
        variables: {
          id: parseInt(this.id),
          term: this.elBusq,
          token: this.token
        },
      });
    },
    check: function () {
      this.cargar = true;
    },
    changeUser: async (id, name) => {
      const alert = await alertController.create({
        header: "¡Atención!",
        message: "Confirma que quiere entrar en el usuario: <br> <br> <b>" + id + "</b> - " + name,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
          },
          {
            text: "Okay",
            id: "confirm-button",
            role: "confirm",
            handler: async () => {
              // Para que el operario de ibancar pueda ver los datos de un cliente, tenemos que crear un token temporal para ese cliente mediante el Authservice.setTokenTemporal se
              //guarda un token en bd en la tabla cliente, campo "token_area_cliente". Es necesario ya que todas las peticiones que lanza graphQL requieren de un token por seguridad
              const temporalToken = await AuthService.setTokenTemporal(id);
              
              // Usamos el Token service para setear el nuevo id y nuevo token del cliente elegido para que al redireccionar al /home el mounted lance la query con los datos de cliente seleccionado
              await TokenService.saveToken(temporalToken);
              await TokenService.saveId(id);
              // window.location.href = "home";
              window.open('home','_blank'); // Abrimos los datos del clietne seleccionado en una ventana aparte
            },
          },
        ],
      });
      return alert.present();
    },
  },
};
