
import { IonRow, IonCol, IonGrid, IonButton, IonText, IonLabel, IonItem, IonToggle, alertController } from "@ionic/vue";
import RowHeaderPeticiones from "../RowHeaderPeticiones.vue";
import ColInputText from "../ColInputText.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "../../../../graphql/queries/clienteQuery";
import { reactive } from "vue";
import { TokenService } from "@/services/token.service";
import ApiService from "@/services/api.service";
import { AuthService } from "@/services/auth.service";

export default {
  name: "GridContacto",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    RowHeaderPeticiones,
    ColInputText,
    IonButton,
    IonText,
    IonLabel,
    IonItem,
    IonToggle,
  },
  setup() {
    const id: any = TokenService.getId();

    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })

    const cliente = useResult(result, null, (data) => data.cliente);

    return {
      cliente,
      loading,
      error,
      currentComponent: "Pendientes",
    };
  },
  methods: {
    checkMobile: function () {
      const ua = navigator.userAgent.toLowerCase();
      if(ua.indexOf("android") > -1  ||  ua.indexOf("iphone") > -1 ){
         window.location.href = 'https://api.whatsapp.com/send?l=es&phone=34913247401&text='+ encodeURI('Viene del área de clientes')
      }
    },
    sendForm: async () => {
      const textarea = document.getElementsByTagName("textarea")["O"];
      if (textarea.value !== "") {
        const myform = document.getElementsByTagName("input");
        const data = {} as any;
        for (const val of myform) {
          data[val.name] = val.value;
        }
        data[textarea.name] = await textarea.value;
        const res = await ApiService.sendMail(data).then(async (res) => {
          if (res.data) {
            const alertSuccess = await alertController.create({
              header: "Enhorabuena",
              subHeader: "El mensaje se ha envíado correctamente, el equipo de Ibancar se pondrá en contacto contigo cuanto antes",
              buttons: ["OK"],
            });
            await alertSuccess.present();
            await alertSuccess.onDidDismiss().then(() => {
              window.location.href = "home";
            });
          } else {
            const errorAlert = await alertController.create({
              header: "¡Atención!",
              subHeader: "No Se ha podido enviar el mensaje ",
              buttons: ["OK"],
            });
            await errorAlert.present();
          }
        });
      } else {
        const errorAlert = await alertController.create({
          header: "¡Atención!",
          subHeader: "Debes introducir un Mensaje",
          buttons: ["OK"],
        });
        errorAlert.present();
      }
    },
  },
  mounted() {
    this.checkMobile();
  },
};
