
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CardPeticion",
  components: { IonCard, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonCardContent,IonImg},
  setup() {
    const router = useRouter();

    return {
      router,
      chevronForward,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
  },
  methods: {
    getIcon: function (name: string) {
      const images = require.context("../src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
  },
  props: {
    title: String,
    icono: String,
    url: String,
  },
};
