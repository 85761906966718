import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "ButtonSolicitud" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col),
      _createVNode(_component_ion_col, {
        class: "ion-text-center no-mobile",
        size: "3",
        "size-lg": "3",
        "size-md": "4",
        "size-xs": "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            class: "ion-text-uppercase ButtonSolicitudPeticionCancel",
            href: "home/peticion"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancelar ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, {
        class: "ion-text-center",
        size: "3",
        "size-lg": "3",
        "size-md": "4",
        "size-xs": "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            class: "ion-text-uppercase ButtonSolicitudPeticionCheck mobile",
            disabled: $props.btnActivo == false,
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.sendSolicitud({'id_tipo': Number($props.codigo),'id': $setup.id,'args': $props.args ? $props.args : '', token: $setup.token})))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.title), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_ion_button, {
            class: "ion-text-uppercase ButtonSolicitudPeticionCheck no-mobile",
            disabled: $props.btnActivo == false,
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.sendSolicitud({'id_tipo': Number($props.codigo),'id': $setup.id,'args': $props.args ? $props.args : '', token: $setup.token})))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Confirmar ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col)
    ]),
    _: 1
  }))
}