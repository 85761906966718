import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40882e08"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tablebody"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "tablePago" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, { class: "header" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" # ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Fecha de Pago ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Importe cuota ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Importe pago ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Forma de Pago ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "3" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Acciones ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      ($options.getCuotas())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.cuotasRealizadas, (cuota) => {
              return (_openBlock(), _createBlock(_component_ion_row, {
                key: cuota.id,
                class: "odd body"
              }, {
                default: _withCtx(() => [
                  (cuota.importepago)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_ion_col, { size: "1" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(cuota.numdelacuota), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($options.formatDate(cuota.fechapago)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(cuota.importecuota) + " € ", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(cuota.importepago) + " € ", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(" Transferencia ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, { size: "3" }, {
                          default: _withCtx(() => [
                            (cuota.id)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  class: "fat-round accion-pago",
                                  href:  $setup.rutaProj + ($setup.acuerdos ? "cliente/recibo/acuerdo/" : "cliente/recibo/") + cuota.id + "?token="+$setup.token,
                                  target: "_blank"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Recibo ")
                                  ]),
                                  _: 2
                                }, 1032, ["href"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]))
        : ($setup.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_loading, {
                        cssClass: "my-custom-class",
                        message: "Cargando sus Pagos...",
                        duration: _ctx.timeout
                      }, null, 8, ["duration"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
      ($options.cuotasRealizadas.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ion_row, { class: "odd body" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode("No se han encontrado Pagos Realizados")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}