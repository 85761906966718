
  import {
    IonGrid,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
  } from "@ionic/vue";
  
  import { useRouter } from "vue-router";
  import MainMenuDesktop from "../items/MainMenuDesktop.vue";
  import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
  import {  reactive } from "vue";  
  import { TokenService } from "@/services/token.service";
  import { useQuery, useResult } from "@vue/apollo-composable";
  import { clienteQuery } from "@/graphql/queries/clienteQuery";

  export default {
    name: "garantia",
    components: {
      IonGrid,
      IonCol,
      IonRow,
      MainMenuDesktop,
      RowHeaderPeticiones,     
      IonCardContent,
      IonCard
    },
    setup() {
      const router = useRouter();
      const id: any = TokenService.getId();
      const variables = reactive({ id: parseInt(id) ,token : TokenService.getToken()});
      const { result } = useQuery(clienteQuery, variables, {
        fetchPolicy: "cache-only",
      });
      const cliente = useResult(result, null, (data) => data.cliente);
      const cuota = useResult(result, null, (data) => data.cliente.contratos.ultimaCuota);
      console.log(cuota);
      require("../css/index.css");
      return {
        router,
        cliente, 
        cuota
      };
    },
    methods: {
      ultimaCuota() {
        if (this.cuota) return this.cuota;
        return false;
      }
    }
  };
  