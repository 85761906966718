import {AuthenticationError, AuthService} from "@/services/auth.service";
import { TokenService } from "../services/token.service";

const state = {
    authenticating: false,
    accessToken: TokenService.getToken(),
    LoginId: TokenService.getId(),
    authenticationErrorCode: 0,
    authenticationError: "",
    refreshTokenPromise: null
};

const getters = {
    authenticationErrorCode: (state: { authenticationErrorCode: any }) => {
        return state.authenticationErrorCode;
    },

    authenticationError: (state: { authenticationError: any }) => {
        return state.authenticationError;
    },

    authenticating: (state: { authenticating: any }) => {
        return state.authenticating;
    },
    LoginId: (state: { LoginId: any }) => {
        return state.LoginId;
    }
};


const actions = {
    async signIn(context: any, signInData: any) {
        context.commit("signInRequest");
        return new Promise((resolve, reject) => {
            AuthService.signIn(signInData).then(res => {
                context.commit("signInSuccess", res);
                resolve(res);
            }).catch(err => {
                if (err instanceof AuthenticationError) {
                    context.commit("signInError", {
                        errorCode: err.errorCode,
                        errorMessage: err.message
                    });
                    reject(err.message);
                }
            });
        });
    },

    signOut(context: any) {
        context.commit("signOutRequest");
        return new Promise((resolve: any) => {
            AuthService.signOut();
            resolve("");
        });
    },

    refreshToken(context: any, state: { refreshTokenPromise: any }) {
        if (!state.refreshTokenPromise) {
            const p = AuthService.refreshToken();
            context.commit("refreshTokenPromise", p);

            p.then(
                response => {
                    context.commit("refreshTokenPromise", null);
                    context.commit("loginSuccess", response);
                },
                error => {
                    context.commit("refreshTokenPromise", error);
                }
            );
        }

        return state.refreshTokenPromise;
    },

    async signUp(context: any, {dni, code, method}: any) {
        try {
            const res = await AuthService.signUp(dni, code, method);
            context.commit("processSuccess");
            return res;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                context.commit("signInError", {
                    errorCode: e.errorCode,
                    errorMessage: e.message
                });
            }
            return 'Error en el Proceso de Conexión';
        }
    },
      //cambios

    async getDataCliente(context: any, {dni, code, method}: any) {
        try {
            const res = await AuthService.getDataCliente(dni, code, method);
            context.commit("processSuccess");
            return res;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                context.commit("signInError", {
                    errorCode: e.errorCode,
                    errorMessage: e.message
                });
            }
            return 'Error en el Proceso de Conexión';
        }
    },
    async sendMailVerificacion(context: any, {dni, code, method}: any) {
        try {
            const res = await AuthService.sendMailVerificacion(dni, code, method);
            context.commit("processSuccess");
            return res;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                context.commit("signInError", {
                    errorCode: e.errorCode,
                    errorMessage: e.message
                });
            }
            return 'Error en el Proceso de Conexión';
        }
    },
    async mailVerificacion(context: any, {token}: any) {
        try {
            const res = await AuthService.mailVerificacion(token);
            context.commit("processSuccess");
            return res;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                context.commit("signInError", {
                    errorCode: e.errorCode,
                    errorMessage: e.message
                });
            }
            return 'Error en el Proceso de Conexión';
        }
    },


    async changePass(context: any, {token, pass}: any) {
        try {
            const res = await AuthService.changePass(token, pass);
            context.commit("processSuccess");
            return res;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                context.commit("signInError", {
                    errorCode: e.errorCode,
                    errorMessage: e.message
                });
            }
            return 'Error en el Proceso de Conexión';
        }
    },

    setAuthenticatingStatus(context: any, status: any) {
        context.commit("setAuthenticatingStatus", status);
    },
};

const mutations = {
    signInRequest(state: {
        authenticating: boolean;
        authenticationError: string;
        authenticationErrorCode: number;
    }) {
        state.authenticating = true;
        state.authenticationError = "";
        state.authenticationErrorCode = 0;
    },

    signInSuccess(state: {
        accessToken: any;
        authenticating: boolean;
    }, accessToken: any) {
        state.accessToken = accessToken;
        state.authenticating = true;
    },

    signInError(state: {
        authenticating: boolean;
        authenticationErrorCode: any;
        authenticationError: any;
    }, {errorCode, errorMessage}: any) {
        state.authenticating = false;
        state.authenticationErrorCode = errorCode;
        state.authenticationError = errorMessage;
    },

    signOutRequest(state: { authenticating: boolean }) {
        state.authenticating = false;
    },

    refreshTokenPromise(state: { refreshTokenPromise: any }, promise: any) {
        state.refreshTokenPromise = promise;
    },

    processSuccess(state: { authenticating: boolean }) {
        state.authenticating = false;
    },

    setAuthenticatingStatus(state: { authenticating: any }, status: any) {
        state.authenticating = status;
    }
};

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
