const TOKEN_KEY = "access_token";
const LOGIN_ID = "login_id";
const REFRESH_TOKEN_KEY = "refresh_token";
const CADUCIDAD = "caducidad";
const ADMIN = 'admin';

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  getId() {
    return localStorage.getItem(LOGIN_ID);
  },
  getCaducidad() {
    return localStorage.getItem(CADUCIDAD);
  },
  getAdmin() {
    return localStorage.getItem(ADMIN);
  },
  saveToken(accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(ADMIN, "0");
  },
  saveId(LoginId: string) {
    localStorage.setItem(LOGIN_ID, LoginId);
  },
  saveCaducidad(caducidad: string){
    localStorage.setItem(CADUCIDAD, caducidad);
  },
  saveAdmin() {
    localStorage.setItem(ADMIN, "1");
  },
  refreshAdmin() {
    localStorage.setItem(ADMIN, "0");
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },
  saveRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
  checkToken() {
    const token = !!TokenService.getToken();
    if(token){
      const current =  new Date();
      const cad = new Date(String(TokenService.getCaducidad()));
      if(current < cad){
        return false
      }      
    }
    return true
  }
};

export { TokenService };
