
  import {
    IonGrid,
    IonApp,
    IonCol,
    IonItem,
    IonAccordion,
    IonLabel,
    IonRow,
    alertController,
    IonCardContent,
    IonCard,
    IonAccordionGroup
  } from "@ionic/vue";
  
  import { mapActions, mapGetters } from "vuex";
  import { useRouter } from "vue-router";
  import Header from "../items/Header.vue";
  import MainMenuDesktop from "../items/MainMenuDesktop.vue";
  import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
  import Footer from "../items/Footer.vue";
  import ButtonSolicitudPeticion from "../items/elements/ButtonSolicitudPeticion.vue";
  import { chevronBack } from "ionicons/icons";
  import { computed, defineComponent, reactive } from "vue";
  import CardPeticion from "../CardPeticion.vue";
  import { TokenService } from "@/services/token.service";
  import { useQuery, useResult } from "@vue/apollo-composable";

  import { clienteQuery } from "@/graphql/queries/clienteQuery";

  export default {
    name: "carencia",
    components: {
      IonGrid,
      IonCol,
      IonRow,
      MainMenuDesktop,
      RowHeaderPeticiones,
      ButtonSolicitudPeticion,
      IonAccordionGroup,
      IonCardContent,
      IonItem,
      IonAccordion,
      IonLabel,
      IonCard
    },
    setup() {
      const router = useRouter();
      const id: any = TokenService.getId();
      const variables = reactive({ id: parseInt(id) ,token : TokenService.getToken()});
      const { result, loading, error } = useQuery(clienteQuery, variables, {
        fetchPolicy: "cache-only",
      });
      const cliente = useResult(result, null, (data) => data.cliente);
      const cuota = useResult(result, null, (data) => data.cliente.contratos.ultimaCuota);

      require("../css/index.css");
      return {
        router,
        cliente,
        cuota
      };
    },
    data() {
      return {
        form: {
          username: "",
          password: "",
        },
      };
    },
    computed: {
      ...mapGetters("auth", [
        "authenticating",
        "authenticationError",
        "authenticationErrorCode",
      ]),
    },
    methods: {
      ...mapActions("auth", ["signIn"]),
      async handleLogin() {
        this.signIn(this.form)
          .then(() => {
            this.form.username = "";
            this.form.password = "";
          })
          .catch(this.alerta());
      },
      alerta() {
        async () => {
          const errorAlert = await alertController.create({
            header: "Error",
            subHeader: "Contraseña Incorrecta",
            buttons: ["OK"],
          });
          await errorAlert.present();
        };
      },
    },
  };
  