
import { IonTabBar, IonTabButton, IonIcon, IonLabel, IonFooter } from "@ionic/vue";
import { personOutline, square, triangle } from "ionicons/icons";
import { useRouter } from "vue-router";
import { useQuery, useResult } from '@vue/apollo-composable';
import { clienteQuery } from '@/graphql/queries/clienteQuery';
import { reactive } from 'vue';
import { TokenService } from '@/services/token.service';
import { AuthService } from "@/services/auth.service";

export default {
  name: "Footer",
  components: { IonTabBar, IonTabButton, IonIcon, IonLabel, IonFooter },
  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onResult, onError } = useQuery(
      clienteQuery,
      variables,
      {
        fetchPolicy: "cache-only",
      }
    );

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })
    
    const cliente = useResult(result, null, (data) => data.cliente);
    return {
      personOutline,
      square,
      loading,
      error,
      triangle,
      cliente
    };
  },
  methods: {
    getIcon: function (name: string) {
      const images = require.context("../src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    goto: function (url: string) {
      window.location.href = url;
    },
    currentRouterName(url: string){
      const current: string = window.location.pathname;
      return !!current.includes(url);
    } 
  }
};
