
import { IonPage, IonContent, IonRow, IonCol, IonItem, IonCheckbox, IonButton, alertController, IonLabel, IonImg, IonText, IonGrid } from "@ionic/vue";
import { logIn, personAdd, alert,eyeOutline, eyeOffOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import ErrorAlert from './items/ErrorAlert.vue';
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "SignIn",
  components: {
    IonPage,
    ErrorAlert,
    IonContent,
    IonRow,
    IonCol,
    IonCheckbox,
    IonButton,
    IonLabel,
    IonImg,
    IonText,
    IonGrid,
    
  },
  setup() {
    const router = useRouter();
    const image = computed(() => require("./src/logo_bl.png"));
    require("./css/login.css");
    return {
      router,
      logIn,
      personAdd,
      image,
      eyeOutline,
      eyeOffOutline
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      form: {
        username: "",
        password: "",
      },
      messageerr: '',
      errorlogin: false,
      errorpwd: false,
      showPassword1: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },

  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(async (res) => {

        if (res.data == false) {
            if(res.error === "usuario no encontrado"){
              this.errorlogin = true
              this.errorpwd = false
              this.messageerr = res.error ? res.error : "La contraseña o usuario no coinciden"
              document.getElementById("check-dni1").style.border = "2px solid #fa0101";
            }else{
              this.errorpwd = true
              this.errorlogin = false
              this.messageerr = res.error ? res.error : "La contraseña o usuario no coinciden";
              document.getElementById("check-dni1").style.border = "0px";
              document.getElementById("check-pwd1").style.border = "2px solid #fa0101";

            }
        } else window.location.reload();
      });
    },
    getIcon: function (name: string) {
      const images = require.context("../Main/src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    togglePasswordVisibility() {
      this.showPassword1 = !this.showPassword1;
    },
  },
};
