

   import { IonButton, IonIcon, IonGrid, IonLabel, IonCol, IonRow} from '@ionic/vue'
   import { chevronForward} from "ionicons/icons";
   import { mapGetters } from "vuex"
   import { useRouter } from 'vue-router';
   
   
   
   
   export default {
     name: 'ButtonMenuList',
     components: {IonButton, IonIcon, IonGrid, IonLabel, IonCol, IonRow},
     setup() {
      
      const router = useRouter();
      const currentRoute = (urlpath: string) => router.hasRoute(urlpath);
      
      return {
        router,
        chevronForward,
        currentRoute
      };
     },
     computed: {
       ...mapGetters("auth", [
         "authenticating",
         "authenticationError",
         "authenticationErrorCode"
       ])
     },
     props:{
       title: String,
       url: String
     }
   }
