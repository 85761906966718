
import {
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  alertController,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import RowHeaderPeticiones from "../RowHeaderPeticiones.vue";
import ColInputText from "../ColInputText.vue";
import ApiService from "@/services/api.service";
import { TokenService } from "@/services/token.service";

export default {
  name: "GridContacto",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    RowHeaderPeticiones,
    ColInputText,
    IonButton,
  },
  setup() {
    return {
      currentComponent: "Pendientes",
    };
  },
  methods: {
    SendPassForm: async () => {
      const form = document.getElementsByTagName("form")["passForm"];
      const input = form.getElementsByTagName("input");
      const data = {} as any;
      const errorAlert = await alertController.create({
        header: "¡Atención!",
        subHeader: "No se ha rellenado el formulario correctamente",
        buttons: ["OK"],
      });
      Object.keys(input).forEach((key) => {
        const row = input[key];
        if (row.value) data[row.name] = row.value;
      });
      if (Object.keys(data).length === 3) {
        if (data.Ca != data.Cn && data.Cn == data.Cnr) {
          data.id = TokenService.getId();
          ApiService.post("/cliente/changePass", data).then(
            async (response) => {
              const res = response.data;
              if (res == 1) {
                const errorAlert = await alertController.create({
                  header: "Confirmado",
                  subHeader:
                    "Su contraseña se ha cambiado correctamente",
                  buttons: ["OK"],
                });
                await errorAlert.present();
              } else {
                const errorAlert = await alertController.create({
                  header: "¡Atención!",
                  subHeader: "La información proporcionada no es correcta",
                  buttons: ["OK"],
                });
                await errorAlert.present();
              }
            }
          );
        } else {
          await errorAlert.present();
        }
      } else {
        await errorAlert.present();
      }
    },
  },
};
