
import {
  IonList,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonProgressBar,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default {
  name: "ProgressBar",
  components: {
    IonList,
    IonListHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonProgressBar,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  props: {
    percentage: Object,
  },
};
