
import {
  IonGrid,
  IonCol,
  IonRow,
  alertController,
  IonCard,
  IonCardContent,
} from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import MainMenuDesktop from "../items/MainMenuDesktop.vue";
import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
import ButtonSolicitudPeticion from "../items/elements/ButtonSolicitudPeticion.vue";

export default {
  name: "Peticion",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    MainMenuDesktop,
    RowHeaderPeticiones,
    ButtonSolicitudPeticion,
    IonCard,
    IonCardContent,
  },
  setup() {
    return {
    };
  },
};
