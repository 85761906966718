import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

class AuthenticationError extends Error {
  errorCode: any;
  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const AuthService = {
    async setTokenTemporal(clienteId: any) {
      const requestData: AxiosRequestConfig = {
        method: "post",
        url: "area/clientes/token/temporal",
        data: qs.stringify({
          id: clienteId
        })
      }
      try {
        const response = await ApiService.customRequest(requestData);
        const data = response.data;
        if (!data.error) {
          return data;
        }

      } catch (error) {
        this.catchError(error)
      }
    },

    signIn: async function(signInData: any) {        
        const requestData: AxiosRequestConfig = {
            method: "post",         
            url: "login/clientes",
            data: qs.stringify({                
                username: signInData.username,
                password: btoa(signInData.password)
            })
        };
        try {
            const response = await ApiService.customRequest(requestData);
            const data = response.data
            if(! data.error){
                TokenService.saveToken(data.data);
                TokenService.saveId(data.id);
                TokenService.saveCaducidad(data.caduca);
                TokenService.saveRefreshToken(data.data);
                ApiService.setHeader();
                ApiService.mount401Interceptor();
                return true;
            }
            return response.data
                        
        } catch (error) {
            this.catchError(error);
        }
    },
    
    //funcion de logeo por el crm que pasamos el token descomprimido que seria el dni para hacer login de ese cliente
    signInCrm: async function(token) {      
      const requestData: AxiosRequestConfig = {
          method: "post",         
          url: "login/clientes_crm",
          data: qs.stringify({                
              token: token,
          })
      };
      try {
          const response = await ApiService.customRequest(requestData);
          const data = response.data
          if(! data.error){
              TokenService.saveToken(data.data);
              TokenService.saveId(data.id);
              TokenService.saveCaducidad(data.caduca);
              TokenService.saveRefreshToken(data.data);
              ApiService.setHeader();
              ApiService.mount401Interceptor();
              window.location.replace("/home/cliente")
          }               
      } catch (error) {
          this.catchError(error);
      }
  },

  refreshToken: async function () {
    const refreshToken = TokenService.getRefreshToken();
    const requestData: AxiosRequestConfig = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          btoa(
            process.env.VUE_APP_CLIENT_ID +
              ":" +
              process.env.VUE_APP_CLIENT_SECRET
          ),
      },
      url: "/oauth/token",
      data: qs.stringify({
        'grant_type': "refresh_token",
        refreshToken: refreshToken,
      }),
    };


    try {
      const response = await ApiService.customRequest(requestData);


      return response.data.access_token;
    } catch (error: any) {
     throw new AuthenticationError(
       error.response.status,
       error.response.data.error_description
     );
    }
  },

  signOut() {
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },

  signUp: async function (dni: any, code: any, method: any) {
    const signupData: AxiosRequestConfig = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/signup/clientes",
      data: {
        dni: dni,
        code: code,
        method: method
      },
    };

    try {
      return await ApiService.customRequest(signupData);
    } catch (error) {
      this.catchError(error);
    }
  },
    //cambios

  getDataCliente: async function (dni: any, code: any, method: any) {
    const signupData: AxiosRequestConfig = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/signup/data/cliente",
      data: {
        dni: dni,
        code: code,
        method: method
      },
    };
    

    try {
      return await ApiService.customRequest(signupData);
    } catch (error) {
      this.catchError(error);
    }
  },
  changePass: async function (token: any, pass: any) {
    const changePass: AxiosRequestConfig = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/changepass/clientes",
      data: {
        token:token,
        pass:pass
      },
    };

    try {
      return await ApiService.customRequest(changePass);
    } catch (error) {
      this.catchError(error);
    }
  },

  catchError: function (error: any) {
    let status;
    let description;

    if (error.response === undefined) {
      status = error.message;
      description = error.message;
    } else {
      status = error.response.status;
      description = error.response.data.error_description;
    }

    throw new AuthenticationError(status, description);
  },
  sendMailVerificacion: async function (dni: any, code: any, method: any) {
    const signupData: AxiosRequestConfig = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/verify/clientes/correo",
      data: {
        dni: dni,
        code: code,
        method: method
      },
    };
    

    try {
      return await ApiService.customRequest(signupData);
    } catch (error) {
      this.catchError(error);
    }
  },
  mailVerificacion: async function (token: any) {
    const signupData: AxiosRequestConfig = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/signup/verify",
      data: {
        token: token,
      },
    };
    try {
      return await ApiService.customRequest(signupData);
    } catch (error) {
      this.catchError(error);
    }
  },
};

export { AuthService, AuthenticationError };
