
  import {
    IonGrid,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
  } from "@ionic/vue";
    
  import MainMenuDesktop from "../items/MainMenuDesktop.vue";
  import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
  import ButtonSolicitudPeticion from "../items/elements/ButtonSolicitudPeticion.vue";
  import { useQuery, useResult } from "@vue/apollo-composable";
  import { TokenService } from "@/services/token.service";
  import { reactive } from "vue";
  import { clienteQuery } from "@/graphql/queries/clienteQuery";


  export default {
    name: "carencia",
    components: {
      IonGrid,
      IonCol,
      IonRow,
      MainMenuDesktop,
      RowHeaderPeticiones,
      ButtonSolicitudPeticion,
      IonCardContent,
      IonCard
    },
    setup() {
      require("../css/index.css");
      const id: any = TokenService.getId();
      const variables = reactive({ id: parseInt(id) ,token : TokenService.getToken()});
      const { result, loading, error } = useQuery(clienteQuery, variables, {
        fetchPolicy: "cache-only",
      });
      const cuota = useResult(result, null, (data) => data.cliente.contratos.ultimaCuota);
      return{
        cuota
      }
    },
  };
  