
import { IonGrid, IonCol, IonRow, alertController } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import MenuList from "./MenuList.vue";
import CardCuota from "./CardCuota.vue";

export default {
  name: "MainMenuDesktop",
  components: { IonGrid, IonCol, IonRow, MenuList, CardCuota },
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form)
        .then(() => {
          this.form.username = "";
          this.form.password = "";
        })
        .catch(this.alerta());
    },
    alerta() {
      async () => {
        const errorAlert = await alertController.create({
          header: "Error",
          subHeader: "Contraseña Incorrecta",
          buttons: ["OK"],
        });
        await errorAlert.present();
      };
    },
  },
};
