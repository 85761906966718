import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, {
    class: "ios list-ios hydrated ProgressBar no-mobile",
    "v-if": $props.percentage.total > 0
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list_header, { class: "ios hydrated" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-start ion-float-left" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Porcentaje completado del préstamo")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-end ion-float-right" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Cuota " + _toDisplayString($props.percentage.pagadas) + " de " + _toDisplayString($props.percentage.total), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_progress_bar, {
        value: $props.percentage.pagadas / $props.percentage.total,
        role: "progressbar",
        color: "success",
        "aria-valuenow": $props.percentage.pagadas,
        "aria-valuemin": "0",
        "aria-valuemax": $props.percentage.total,
        class: "ios progress-bar-determinate hydrated"
      }, null, 8, ["value", "aria-valuenow", "aria-valuemax"])
    ]),
    _: 1
  }, 8, ["v-if"]))
}