
import { IonRow, IonCol } from "@ionic/vue";

export default {
    name: "ErrorAlert",
    components: { IonRow, IonCol },
    props: {
        title: String,
        mobile: Boolean
    }
};
