import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenuDesktop = _resolveComponent("MainMenuDesktop")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_GridPago = _resolveComponent("GridPago")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "main-grid" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "4",
            class: "no-mobile"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MainMenuDesktop)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_GridPago)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}