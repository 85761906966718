
import { IonGrid, IonApp, IonCol, IonRow, alertController } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import Header from "./items/Header.vue";

import Footer from "./items/Footer.vue";
import MainMenuDesktop from "./items/MainMenuDesktop.vue";
import GridPassword from "./items/grids/GridPassword.vue";

export default {
  name: "Password",
  components: { IonGrid, IonCol, IonRow, MainMenuDesktop, GridPassword },
  setup() {
    const router = useRouter();

    require("./css/index.css");
    return {
      router,
    };
  },
 
};
