
import { IonPage, alertController, IonImg } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "Renew",
  components: { IonPage, IonImg },
  setup() {
    const router = useRouter();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const tokenParsed = "";
    const check = computed(() => require("./src/ok_check.png"));
    if (token) {
      const tokenParsed = JSON.parse(decodeURI(atob(atob(token))));
    } else if (token.length == 0) window.location.href = "/login";
    const image = computed(() => require("./src/logo_bl.png"));
    require("./css/login.css");
    return {
      router,
      logIn,
      personAdd,
      image,
      token,
      tokenParsed,
      check
    };
  },
  data() {
    return {
      pass: '',
      passR: '',
      step: 1,
      windowHeight: window.innerWidth,
      errorpwd: true,
      messageerr: ''

    };
  },
  methods: {
    ...mapActions("auth", ["changePass"]),
    async CheckPass() {
      // Comprobamos que se rellena este campo y contiene minimo 6 caracteres
      if ((this.pass == undefined || this.passR == undefined) || (this.pass.length < 6 && this.passR.length < 6)) {
        this.messageerr = "Las contraseñas deben de tener mas de 6 carácteres"
        this.errorpwd = false;
        document.getElementById("check-pwd1").style.border = "2px solid #fa0101";
      }

      else if (this.pass == this.passR) {
        document.getElementById("check-pwd1").style.border = "0px";
        this.errorpwd = true
        this.changePass({
          pass: this.pass,
          token: this.token,
        }).then(async (res) => {
          let response = res.data;
          if (response.response == true) {
            this.step = 2

          } else {
              this.errorpwd = false;
              this.messageerr = response.message // // Mostramos mensaje KO de servidor
          }
        });
      } else {
        this.errorpwd = false;
        this.messageerr = "Las contraseñas no coinciden"
        document.getElementById("check-pwd1").style.border = "2px solid #fa0101";
      }
    },
    async tokenValid(date) {
      date = new Date(date);
      const anHourAgo = Date.now() - 1000 * 60 * 60;
      if (date.getTime() < anHourAgo) {
        const errorAlert = await alertController.create({
          header: "Atención",
          message: "Token Caducado, ha pasado demasiado tiempo desde la solicitud, le recomendamos que solicite otro",

          buttons: [
            {
              text: "Cerrar",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: () => {
                location.replace("signup");
              },
            },
          ],
        });
        await errorAlert.present();
      }
    },
    onResize() {
      this.windowHeight = window.innerWidth
    },
  },
  mounted() {
    this.tokenValid(this.tokenParsed.date);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
};
