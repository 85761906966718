
import { IonCol, IonLabel, IonItem } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ColInputText",
  components: { IonCol, IonLabel, IonItem },
  setup() {
    return {};
  },
  props: {
    title: String,
    area: Boolean,
    password: Boolean,
    placeholder: String,
    value: String,
    name: String
  },
};
