import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    href: 'peticiones/' + $props.url,
    class: "white round card-peticion"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, { class: "ion-text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString($props.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col),
                  _createVNode(_component_ion_col, { style: {"text-align":"center"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        src: $options.getIcon($props.icono)
                      }, null, 8, ["src"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["href"]))
}