
import { mapActions, mapGetters } from "vuex";
import { IonRow, IonCol, IonGrid, IonIcon, IonButton, IonCardTitle, IonCardHeader, IonCardContent, IonCard } from "@ionic/vue";
import { chevronDown, chevronUp } from "ionicons/icons";
import RowHeaderPeticiones from "../RowHeaderPeticiones.vue";
import PagoPendiente from "../PagoPendiente.vue";
import PagoRealizado from "../PagoRealizado.vue";
import CardCuota from "../CardCuota.vue";
import { TokenService } from "@/services/token.service";
import { reactive } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import moment from "moment";
import { AuthService } from "@/services/auth.service";

export default {
  name: "GridPeticiones",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    CardCuota,
    IonIcon,
    RowHeaderPeticiones,
    PagoPendiente,
    PagoRealizado,
    IonButton,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
  },
  data() {
    return {
      c1: true,
      c2: false,
    };
  },
  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })

    const rutaProj = process.env.VUE_APP_ROOT_API

    const estadov2 = useResult(result, null, (data) => data.cliente.contratos.estadov2);
    const contrato = useResult(result, null, (data) => data.cliente.contratos.cuotas);

    const acuerdos = useResult(result, null, (data) => data.cliente.acuerdos.cuotas);
    
    
    return {
      currentComponent: "Pendientes",
      chevronDown,
      error,
      loading,
      chevronUp,
      contrato,
      rutaProj,
      acuerdos,
      estadov2,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async dropdown(echo: any) {
      if (echo === "p") {
        this.c1 = !this.c1;
      }
      if (echo === "r") {
        this.c2 = !this.c2;
      }
    },
    async swapComponents(echo: any) {
      if (echo === "p") {
        this.c1 = true;
        this.c2 = false;
      }
      if (echo === "r") {
        this.c2 = true;
        this.c1 = false;
      }
    },
    diffDate(date1: Date, date2: Date) {
      return Math.abs((new Date(date1).getTime() - new Date(date2).getTime()) / (24 * 60 * 60 * 1000));
    },
    formatDate(date) {
      if (screen.width < 700) return moment(date).format("DD/MM/YY");
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
