
import { IonPage, IonImg, IonIcon } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import ErrorAlert from "./items/ErrorAlert.vue";
import { eyeOutline, eyeOffOutline, alertCircle, mail, call, logoWhatsapp } from 'ionicons/icons';
import { store } from "../../store/index";
import functions from "../Main/src/functions";


export default {
  name: "SignUp",
  components: { IonPage, IonImg, IonIcon, ErrorAlert},
  setup() {
    const router = useRouter();
    const image = computed(() => require("./src/logo_bl.png"));
    const okImage = computed(() => require("./src/ok_crear_cuenta_movil.png"));
    const kOImage = computed(() => require("./src/ko_crear_cuenta_movil.png"));


    require("./css/login.css");
    return {
      router,
      logIn,
      personAdd,
      image,
      eyeOutline,
      eyeOffOutline,
      alertCircle,
      mail,
      call,
      okImage,
      kOImage,
      logoWhatsapp,
      functions
    };
  },
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      dniError: false,
      pwdEquals: true,
      smsSelected: false,
      emailSelected: false,
      step: 1,
      form: {
        dni: '',
        password1: '',
        password2: '',
        method: '',
      },
      email: '',
      sms: '',
      windowHeight: window.innerWidth,
      resendEnabled: true,
      remainingTime: 60,
      timerId: null,
      buttonDisabled: true
    };
  },
  store,
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
    areInputsValid() {
      // Verifica si los campos de entrada tienen datos
      return this.form.password1.trim() != "" && this.form.password2.trim() != "" && functions.validateDNI(this.form.dni.trim());
    },
    maskedEmail() {
      if (this.email.includes("@")) {
        const [username, domain] = this.email.split("@");
        const maskedUsername = username.slice(0, 3) + "XXX";
        return `${maskedUsername}@${domain}`;
      }
      return this.email;
    },
    maskedSMS() {
      const len = this.sms.length;
      if (len >= 3) {
        const maskedPart = "X".repeat(len - 3) + ' ' + this.sms.slice(-3);
        const formattedSMS = maskedPart.replace(/(...)(...)/, "$1 $2");
        return formattedSMS;
      }
      return this.sms;
    },
  },

  methods: {
    ...mapActions("auth", ["getDataCliente", "sendMailVerificacion"]),
    async handleSingUp() {
      this.dniError = false;
      this.pwdEquals = true;
      document.getElementById("check-dni").style.border = "0px";
      if (this.form.password1 != this.form.password2) {
        this.pwdEquals = false;
        document.getElementById("check-pwd1").style.border = document.getElementById("check-pwd2").style.border = "2px solid #fa0101";

      } else {
        document.getElementById("check-pwd1").style.border = document.getElementById("check-pwd2").style.border = "0px";
        
        this.pwdEquals = true;
        this.getDataCliente(this.form).then(async (res) => {
          res = res.data;
          if (res.error) {
            this.dniError = true;
            document.getElementById("check-dni").style.border = "2px solid #fa0101";
          } else {
            this.$nextTick(() => {
              document.getElementById("check-dni").style.border = "0px";
              this.step = 2;
              this.email = res.def_email1;
              this.sms = res.def_telefono1;
            });
          }
        });
      }
    },
    togglePasswordVisibility(fieldNumber) {
      if (fieldNumber === 1) {
        this.showPassword1 = !this.showPassword1;
      } else if (fieldNumber === 2) {
        this.showPassword2 = !this.showPassword2;
      }
    },
    toggleEnvioSelected(tipo) {
      if (tipo === 'email') {
        this.emailSelected = true
        this.buttonDisabled = false
        this.smsSelected = false
        this.form.method = 'mail'
      } else {
        this.emailSelected = false
        this.smsSelected = true
        this.form.method = 'sms'
        this.buttonDisabled = false

      }
    },
    getIcon: function (name: string) {
      const images = require.context("../Main/src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    onResize() {
      this.windowHeight = window.innerWidth
    },
    async sendVerification() {
      if (!this.resendEnabled) {
        return;
      }

      this.sendMailVerificacion(this.form).then(async (res) => {
        res = res.data;
        if (res.error) {
          this.step = 4;
        } else {
          this.step = 3;
          this.disableResendFor60Seconds();
        }
      });
    },
    async disableResendFor60Seconds() {
      this.remainingTime = 60;
        if(this.resendEnabled && this.remainingTime == 60){
          this.resendEnabled = false;
          this.timerId = setInterval(() => {    
            if (this.remainingTime <= 0) {
              this.resendEnabled = true;
              clearInterval(this.timerId);
            }
            else {
              this.remainingTime--
            }
          }, 1000); 
        }
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    clearInterval(this.timerId); // Clear the interval to avoid memory leaks

  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

};
