
import { IonButton, IonGrid, IonCol, IonRow, IonCardContent, IonCard, IonSkeletonText, IonText } from "@ionic/vue";
import { mapGetters } from "vuex";
import { reactive } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { TokenService } from "@/services/token.service";
import { clienteQuery } from "../../../graphql/queries/clienteQuery";
import functions from "../src/functions";
import { AuthService } from "@/services/auth.service";

export default {
  name: "CardCuota",
  components: {
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
    IonSkeletonText,
    IonText,
  },

  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
        window.location.href="/"; 
      });

    const cuota = useResult(result, null, (data) => data.cliente.contratos.ultimaCuota);
    const acuerdos = useResult(result, null, (data) => data.cliente.acuerdos.getProximaCuotaClientes);
    const estadov2 = useResult(result, null, (data) => data.cliente.contratos.estadov2);
    return {
      cuota,
      estadov2,
      loading,
      error,
      acuerdos,
      functions,
      TokenService
    };
  },
  methods: {
    ultimaCuota() {
      if (this.acuerdos) return this.acuerdos;
      return this.cuota;
    },
    abonar(id){
      functions.abonar(id, !!this.acuerdos)
    }
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
};
