import gql from "graphql-tag";

export const selectClientes: any = gql`
  query listCliente($term: String,$token: String, $id: Int ) {
    listCliente(term: $term, token: $token, id: $id) {
      id
      estado
      tipo
      def_nifcif
      def_tipo
      def_nombre
      def_apellidos
      def_direccion 
    }
  }
`;
