
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonItem,
  IonIcon,
  IonImg,
  IonList,
  IonContent,
  IonPopover,
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { computed, defineComponent } from "vue";

export default {
  name: "Header",
  components: {
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonImg,
    IonIcon,
    IonItem,
    IonList,
    IonContent,
    IonPopover,
  },
  setup() {
    const router = useRouter();
    const image = computed(() => require("../src/logo_az.png"));
    return {
      router,
      chevronForward,
      image,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
  },
  methods: {
    getIcon: function (name: string) {
      const images = require.context("../src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
  },
};
