
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonDatetime,
} from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import MainMenuDesktop from "../items/MainMenuDesktop.vue";
import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
import { reactive } from "vue";
import ButtonSolicitudPeticion from "../items/elements/ButtonSolicitudPeticion.vue";
import { TokenService } from "@/services/token.service";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import { AuthService } from "@/services/auth.service";

export default {
  name: "cambio",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    MainMenuDesktop,
    RowHeaderPeticiones,
    ButtonSolicitudPeticion,
    IonDatetime,
  },
  data(){
    return {
      customDatetime: ""
    }
  },
  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });

    const { result, loading, error, onResult, onError } = useQuery(
      clienteQuery,
      variables,
      {
        fetchPolicy: "cache-only",
      }
    );

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })
      
    const cliente = useResult(result, null, (data) => data.cliente);
    const router = useRouter();
    require("../css/index.css");
    return {
      router,
      error,
      cliente
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    getSelectedDate(el) {
      this.customDatetime = el;
      return  this.customDatetime;
    },
    getCurrDate() {
      return new Date().toJSON().slice(0, 10);
    },
    addDays(date: Date): string {
      const data = new Date(date);
      const time = data.getTime();
      data.setTime(time + 1296000000);
      return (
        data.getFullYear() +
        "-" +
        (data.getUTCMonth() + 1).toString().padStart(2, "0") +
        "-" +
        data.getDate().toString().padStart(2, "0")
      );
    },

  },
};
