import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (! $setup.currentRoute($props.url))
    ? (_openBlock(), _createBlock(_component_ion_button, {
        key: 0,
        class: "round menu-button",
        href: $props.url,
        expand: "block"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    class: "ion-text-start",
                    size: "11"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString($props.title), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    class: "ion-text-end",
                    size: "1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.chevronForward }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["href"]))
    : (_openBlock(), _createBlock(_component_ion_button, {
        key: 1,
        class: "round menu-button menu-active",
        href: $props.url,
        expand: "block"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    class: "ion-text-start",
                    size: "11"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { style: {} }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString($props.title), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    class: "ion-text-end",
                    size: "1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.chevronForward }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["href"]))
}