import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {store} from '@/store';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";

import { ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option';
import { DefaultApolloClient } from '@vue/apollo-composable'
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_ROOT_API+'graphql'
});

const authLink = setContext((_, { headers }) => {
  const token =  TokenService.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});



const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,  
})

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(apolloProvider)
    .provide(DefaultApolloClient, apolloClient)
    .use(store);

ApiService.init(process.env.VUE_APP_ROOT_API);

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}
  
router.isReady().then(() => {
  app.mount('#app');
});
