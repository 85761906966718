import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowHeaderPeticiones = _resolveComponent("RowHeaderPeticiones")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return ($data.cargar)
    ? (_openBlock(), _createBlock(_component_ion_grid, {
        key: 0,
        class: "main-grid TextCardPeticion"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_RowHeaderPeticiones, {
                        title: "Administrador de Usuarios",
                        operarioIbancarId: $data.operarioIbancarId,
                        operarioIbancarToken: $data.operarioIbancarToken
                      }, null, 8, ["operarioIbancarId", "operarioIbancarToken"]),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, {
                                class: "white peticion-card",
                                style: {"height":"auto !important"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_toolbar, { style: {"background-color":"white"} }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_searchbar, {
                                            placeholder: "Nombre, DNI, id, ...",
                                            modelValue: $data.elBusq,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.elBusq) = $event)),
                                            onKeydown: $options.BusquedaClientes,
                                            onChange: $options.BusquedaClientes
                                          }, null, 8, ["modelValue", "onKeydown", "onChange"])
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.clientes, (el) => {
                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                          key: el.id,
                                          onClick: ($event: any) => ($options.changeUser(el.id, el.def_nombre))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(el.id) + " - " + _toDisplayString(el.def_nombre) + " " + _toDisplayString(el.def_apellidos) + " [ " + _toDisplayString(el.def_nifcif) + " ]", 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}