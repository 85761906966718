import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-500825c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tablebody"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "tablePago" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, { class: "header" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" # ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Fecha de Pago ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Importe ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Acciones ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      ($options.getCuotas())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getCuotas(), (cuota) => {
              return (_openBlock(), _createBlock(_component_ion_row, {
                key: cuota.id,
                class: "even body"
              }, {
                default: _withCtx(() => [
                  (!cuota.importepago)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_ion_col, { size: "1" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(cuota.numdelacuota), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($options.formatDate(cuota.fecha)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(cuota.importecuota) + "€ ", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              class: "fat-round accion-pago",
                              onClick: ($event: any) => ($options.abonar(cuota.id))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Abonar ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]))
        : ($setup.espera)
          ? (_openBlock(), _createBlock(_component_ion_loading, {
              key: 1,
              cssClass: "my-custom-class",
              message: "Cargando sus Pagos..."
            }))
          : (_openBlock(), _createBlock(_component_ion_row, {
              key: 2,
              class: "odd body"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode("No se han encontrado más cuotas pendientes.")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
    ]),
    _: 1
  }))
}