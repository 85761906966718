
import { IonGrid, IonCol, IonRow } from "@ionic/vue";
import { chevronBack } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { computed, defineComponent, reactive } from "vue";
import RowHeaderPeticiones from "../RowHeaderPeticiones.vue";
import CardPeticion from "../CardPeticion.vue";
import { TokenService } from "@/services/token.service";
import { useQuery, useResult } from "@vue/apollo-composable";
import functions from "../../src/functions";

import { clienteQuery } from "@/graphql/queries/clienteQuery";
import { AuthService } from "@/services/auth.service";

export default {
  name: "GridPeticiones",
  components: { IonGrid, IonCol, IonRow, CardPeticion, RowHeaderPeticiones },
  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })
      
    const cliente = useResult(result, null, (data) => data.cliente);
    const router = useRouter();
    const cuota = useResult(result, null, (data) => data.cliente.contratos.ultimaCuota);
    const acuerdos = useResult(result, null, (data) => data.cliente.acuerdos.getProximaCuotaClientes);
    return {
      router,
      error,
      chevronBack,
      cliente,
      acuerdos,
      cuota,
      loading,
      functions,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
  methods: {
    ultimaCuota() {
      if (this.acuerdos) return this.acuerdos;
      return this.cuota;
    },
  },
};
