
import {
  IonImg,
  IonIcon,
  IonGrid,
  IonLabel,
  IonCol,
  IonRow,
  IonCard,
  IonCardContent,
  IonSkeletonText,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { computed, reactive } from "vue";
import MenuList from "./items/MenuList.vue";
import Skeleton from "./items/elements/skeleton.vue";
import ProgressBar from "./items/elements/ProgressBar.vue";
import CardCuota from "./items/CardCuota.vue";
import { TokenService } from "@/services/token.service";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "../../graphql/queries/clienteQuery";
import moment from 'moment';
import { AuthService } from "@/services/auth.service";

export default {
  name: "Index",
  components: {
    IonGrid,
    MenuList,
    ProgressBar,
    CardCuota,
    IonIcon,
    IonImg,
    IonLabel,
    IonCol,
    IonRow,
    IonCard,
    IonCardContent,
    IonSkeletonText,
    Skeleton,
  },
  setup() {
    require("./css/index.css");
    const router = useRouter();
    const image = computed(() => require("./src/logo_az.png"));
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });

    const { result, loading, error, onResult, onError } = useQuery(
      clienteQuery,
      variables,
      {
        fetchPolicy: "network-only",
      }
    );

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })


    const handleResult = (data) => {
      if (data && data.cliente) {
        TokenService.refreshAdmin();
        if (data.cliente.administrador_area_cliente == 1)   TokenService.saveAdmin();
   
      }
    };
    onResult(({ data }) => handleResult(data));


    

    const e = useResult(result,null, (data) => data);

    const cliente = useResult(result, null, (data) => data.cliente );

    const estadov2 = useResult(
      result,
      null,
      (data) => data.cliente.contratos.estadov2
    );
    return {
      router,
      image,
      loading,
      error,
      estadov2,
      cliente,
    };
  },
  methods: {
    getIcon: function (name: string) {
      const images = require.context("./src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    formatDate(date){
      if(screen.width < 700 ) return moment(date).format("DD/MM/YY")
      return moment(date).format("DD/MM/YYYY")
    },
    calcPercentage: function (cliente: Record<"contratos", any>) {
      if (cliente.contratos !== null) {
        const cuotas = cliente.contratos.cuotas;
        let totalcuotas = 0;
        let pagadascuotas = 0;
        cuotas.forEach((element: any) => {
          totalcuotas++;
          if (element.estado == 1 || element.estado == 5) pagadascuotas++;
        });
        return { total: totalcuotas, pagadas: pagadascuotas };
      }
    },
  },
};
