
import { IonGrid, IonApp, IonCol, IonRow, alertController } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import MainMenuDesktop from "./items/MainMenuDesktop.vue";
import GridContacto from "./items/grids/GridContacto.vue";

export default {
  name: "contacto",
  components: { IonGrid, IonCol, IonRow, MainMenuDesktop, GridContacto },
  setup() {
    const router = useRouter();

    require("./css/index.css");
    return {
      router,
    };
  },
};
