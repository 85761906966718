import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d35767d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "placeholder"]
const _hoisted_2 = ["name", "value", "placeholder"]
const _hoisted_3 = ["name", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!

  return (_openBlock(), _createBlock(_component_ion_col, {
    "size-lg": "",
    "size-sm": "12",
    "size-xs": "12"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "stacked" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.title), 1)
            ]),
            _: 1
          }),
          ($props.area)
            ? (_openBlock(), _createElementBlock("textarea", {
                key: 0,
                class: "campo-form",
                name: $props.name,
                value: $props.value,
                placeholder: $props.placeholder
              }, null, 8, _hoisted_1))
            : ($props.password)
              ? (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "campo-form",
                  name: $props.name,
                  value: $props.value,
                  type: "password",
                  placeholder: $props.placeholder
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("input", {
                  key: 2,
                  class: "campo-form",
                  name: $props.name,
                  value: $props.value,
                  type: "text",
                  placeholder: $props.placeholder
                }, null, 8, _hoisted_3))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}