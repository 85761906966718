
import { IonGrid, IonCol, IonRow } from "@ionic/vue";
import { useRouter } from "vue-router";
import MainMenuDesktop from "./items/MainMenuDesktop.vue";
import GridPago from "./items/grids/GridPago.vue";
import moment from 'moment';

export default {
  name: "Pago",
  components: { IonGrid, IonCol, IonRow, MainMenuDesktop, GridPago },
  setup() {
    const router = useRouter();
    require("./css/index.css");
    return {
      router,
    };
  },
};
