import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return ($props.sentence)
    ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.sentence), 1)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
        key: 1,
        animated: "",
        style: _normalizeStyle('width:'+$props.width+' %')
      }, null, 8, ["style"]))
}