
import { IonLabel, IonCol, IonRow, IonIcon, IonFabButton } from "@ionic/vue";
import { chevronBack } from "ionicons/icons";
import { TokenService } from "@/services/token.service";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "RowHeaderPeticiones",
  components: { IonCol, IonRow, IonLabel, IonIcon, IonFabButton},
  setup(props) {
    const current = window.location.pathname;
    const currentRoute = (url: string) => current.includes(url);
    const router = useRouter();
    const route = useRoute();

    const volver = async () => {
      // si en la navegación se pincha en volver para retroceder desde "admin usuarios" tenemos que volver a setear el id y token del cliente con el del usuario ibancar
      if (route.path == "/home/admin") {
        TokenService.saveId(props.operarioIbancarId);
        TokenService.saveToken(props.operarioIbancarToken);
      }
      // history.back();
      return await router.go(-1);
    }

    return {
      router,
      chevronBack,
      currentRoute,
      volver,
    }
  },
  props: {
    title: String,
    operarioIbancarId: String,
    operarioIbancarToken: String
  },
};
