import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowHeaderPeticiones = _resolveComponent("RowHeaderPeticiones")!
  const _component_CardPeticion = _resolveComponent("CardPeticion")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return ($setup.cliente)
    ? (_openBlock(), _createBlock(_component_ion_grid, {
        key: 0,
        class: "gridPeticiones"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_RowHeaderPeticiones, { title: "Peticiones " }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                "size-md": "12",
                "size-xs": "12",
                "size-sm": "12",
                "size-lg": "4"
              }, {
                default: _withCtx(() => [
                  ($setup.functions.compareDates($options.ultimaCuota().fecha) > -6 && !$setup.cliente.contratos.estadov2.includes('c2'))
                    ? (_openBlock(), _createBlock(_component_CardPeticion, {
                        key: 0,
                        title: "Ampliación del préstamo",
                        icono: "stonks",
                        url: "ampliacion"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              ($setup.functions.compareDates($options.ultimaCuota().fecha) > -6 && !$setup.cliente.contratos.estadov2.includes('c2'))
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    "size-md": "12",
                    "size-xs": "12",
                    "size-sm": "12",
                    "size-lg": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CardPeticion, {
                        title: "Cambio en fecha de cuota",
                        icono: "cambioCalendar",
                        url: "cambio"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, {
                "size-md": "12",
                "size-xs": "12",
                "size-sm": "12",
                "size-lg": "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CardPeticion, {
                    title: "Cancelación anticipada",
                    icono: "cancel",
                    url: "cancelacion"
                  })
                ]),
                _: 1
              }),
              (($setup.cliente.contratos.idproductos == 13 || $setup.cliente.contratos.idproductos == 14) && $setup.functions.compareDates($options.ultimaCuota().fecha) > 0  && !$setup.cliente.contratos.estadov2.includes('c2'))
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 1,
                    "size-md": "12",
                    "size-xs": "12",
                    "size-sm": "12",
                    "size-lg": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CardPeticion, {
                        title: "Cancelación parcial",
                        icono: "cancelpar",
                        url: "cancelacionp"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($setup.functions.compareDates($options.ultimaCuota().fecha) > -6  && !$setup.cliente.contratos.estadov2.includes('c2'))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_ion_col, {
                      "size-md": "12",
                      "size-xs": "12",
                      "size-sm": "12",
                      "size-lg": "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CardPeticion, {
                          title: "Mes de carencia",
                          icono: "calendar",
                          url: "carencia"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      "size-md": "12",
                      "size-xs": "12",
                      "size-sm": "12",
                      "size-lg": "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CardPeticion, {
                          title: "Informe de tráfico",
                          icono: "editar",
                          url: "trafico"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      "size-md": "12",
                      "size-xs": "12",
                      "size-sm": "12",
                      "size-lg": "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CardPeticion, {
                          title: "Revisión Midas",
                          icono: "repair",
                          url: "revision"
                        })
                      ]),
                      _: 1
                    }),
                    ($setup.cliente.contratos.tipo_contrato.garantia)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 0,
                          "size-md": "12",
                          "size-xs": "12",
                          "size-sm": "12",
                          "size-lg": "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CardPeticion, {
                              title: "Garantía Protección Pagos",
                              icono: "garantia",
                              url: "garantias"
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}