
import { IonGrid, IonCol, IonRow, IonCardContent, IonCardHeader, IonCard } from "@ionic/vue";

import { useRouter } from "vue-router";
import MainMenuDesktop from "../items/MainMenuDesktop.vue";
import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
import { TokenService } from "@/services/token.service";
import { reactive } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import moment from "moment";
import { AuthService } from "@/services/auth.service";

export default {
  name: "historial",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    MainMenuDesktop,
    RowHeaderPeticiones,
    IonCardContent, 
    IonCardHeader,
    IonCard
  },
  setup() {
    const router = useRouter();
    moment.locale("es");
    const id: any = TokenService.getId();
    const tipos = {};
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-and-network",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })

    const cliente = useResult(result, null, (data) => data.cliente);
    return {
      moment,
      loading,
      error,
      cliente,
    };
  },
  methods: {
    estado(estado: string) {
      estado = estado.charAt(0);
      if (estado == "A") return "<ion-text>Aprobado</ion-text>";
      else return "<ion-text>Denied</ion-text>";
    },
  },
};
