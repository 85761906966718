import ApiService from "@/services/api.service";
import { alertController } from "@ionic/vue";
import axios from "axios";
import moment from "moment";

const functions = {
  formatDate(date) {
    moment.locale('es');
    if (screen.width < 700) return moment(date).format("DD/MM/YY");
    return moment(date).format("DD/MM/YYYY");
  },
  abonar: function (id, acuerdo) {
    ApiService.get(process.env.VUE_APP_ROOT_API+"kineox/get/token/" + id + (acuerdo ? "/true" : "/false") + "/a").then((res) => {
      if (res.data.message) this.lanzarAlert("Cuota No Encontrada");
      else window.location.replace(res.data);
    });
  },
  validateDNI(dni) {
    // Comprueba si es un DNI correcto (entre 5 y 8 letras seguidas de la letra que corresponda).
    // Acepta NIEs (Extranjeros con X, Y o Z al principio)
    let numero, cont, letra;
    let regExpression = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if(regExpression.test(dni) === true){
        numero = dni.substr(0,dni.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        cont = dni.substr(dni.length-1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != cont) {
            //DNI inventado
            return false;
        }else{
            //👍
            return true;
        }
    }else{
        //Formato Inválido
        return false;
    }
  },
  lanzarAlert: async (msg) => {
    const errorAlert = await alertController.create({
      header: "Atención",
      message: msg,

      buttons: [
        {
          text: "Cerrar",
          role: "cancel",
          cssClass: "secondary",
          id: "cancel-button",
        },
      ],
    });
    await errorAlert.present();
  },
  compareDates(cuota: string) {
    return Math.ceil((Date.parse(cuota) - new Date().getTime()) / (1000 * 60 * 60 * 24));
  },
  currentDate() {
    const current = new Date();
    const date = current.getDate() + "/" + (current.getMonth() + 1) + "/" + current.getFullYear();
    return date;
  },
  numberFormat(num) {
    return Intl.NumberFormat("de-DE", {
      style: "currency",
      minimumIntegerDigits: 2,
      currency: "EUR",
    }).format(num);
  },
  cancelacionAnticipada(idc) {
    const data = axios.get("getCancelacionAnticipadaDesglose/" + idc);
    return data;
  },
};
export default functions;
