
import { IonRow, IonCol, IonButton, alertController } from "@ionic/vue";
import { useMutation } from "@vue/apollo-composable";
import { TokenService } from "@/services/token.service";
import { solicitudMutacion } from "@/graphql/queries/solicitudMutacion";

export default {
  name: "ButtonSolicitudPeticion",
  components: { IonRow, IonCol, IonButton },
  setup() {
    const token = TokenService.getToken();
    const modal = async (data) => {
      let mensaje;
      if(data == 0){
        mensaje = "Ya estamos gestionando una de sus peticiones realizadas anteriormente, espere su resolución para realizar una nueva, le informaremos del resultado vía e-mail lo más pronto posible.";
      } else if (data == 483){
        mensaje = "Lo sentimos. Su solicitud de ampliación no puede ser procesada, ya ha alcanzado el capital máximo que le podemos prestar.<br>¡Muchas Gracias!";
      } else if (data == 482){
        mensaje = "Lo sentimos. Su solicitud de ampliación no puede ser procesada, recientemente ya le fue denegada una solicitud.<br>¡Muchas Gracias!";
      } else if (data == 481){
        mensaje = "Lo sentimos. Su solicitud de ampliación no puede ser procesada, aún tiene cuotas pendientes de pago.<br>¡Muchas Gracias!";
      } else {
        mensaje = "Solicitud Enviada. Recibirás un email con el resultado en un plazo de 48 horas hábiles.<br>¡Muchas Gracias!";
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Atención",
        message: mensaje,
        buttons: ["Cerrar"],
      });
      alert.present();
      const { role } = await alert.onDidDismiss();
      window.location.href = "home/peticion";
    };
    const { mutate: sendSolicitud } = useMutation(solicitudMutacion, {
      update: (cache, { data: datos }) => {
        modal(datos.createSolicitud.id_tipo);
      },
    });
    const id = Number(TokenService.getId());
    return {
      sendSolicitud,
      id,
      token
    };
  },
  props: {
    title: String,
    alerta: String,
    codigo: Number,
    args: String,
    btnActivo: {
        type: Boolean,
        default: true
    },
  },
};
