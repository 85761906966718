
import { IonGrid, IonCol, IonRow } from "@ionic/vue";
import { useRouter } from "vue-router";
import MainMenuDesktop from "./items/MainMenuDesktop.vue";
import GridMPago from "./items/grids/GridMPago.vue";

export default {
  name: "Pago",
  components: { IonGrid, IonCol, IonRow, MainMenuDesktop, GridMPago },
  setup() {
    const router = useRouter();
    require("./css/index.css");
    return {
      router,
    };
  }
};
