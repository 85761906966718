import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { home } from "./home.store";

export const store = createStore({
    state: {
        email: '',
        sms: ''
    },
    mutations: {
        setDataEmail(state, email){
			state.email = email;
		},
        setDataSMS(state, sms){
			state.sms = sms;
		},
    },
    actions: {},
    modules: {
        auth,
        home
    },
})
