
import { IonText, IonSkeletonText } from "@ionic/vue";
import { useRouter } from "vue-router";

export default {
  name: "Skeleton",
  components: {
    IonText,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  props: {
    sentence: String,
    width: Number
  },
};
