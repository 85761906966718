
import { mapActions, mapGetters } from "vuex";
import { IonToggle, IonGrid, IonCol, IonItem, IonCardTitle,  IonLoading, IonRow, IonCard, IonCardContent,IonLabel, IonCardHeader, IonCardSubtitle, alertController, IonButton} from "@ionic/vue";
import { chevronDown, chevronUp } from "ionicons/icons";
import RowHeaderPeticiones from "../RowHeaderPeticiones.vue";
import { TokenService } from "@/services/token.service";
import { reactive } from "vue";
import { useMutation, useQuery, useResult, useLazyQuery} from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import moment from "moment";
import ApiService from "@/services/api.service";
import { tarjetaPredeterminadaMutacion } from "@/graphql/queries/tarjetaPredeterminadaMutacion";
import { tarjetaMutacion } from "@/graphql/queries/tarjetaMutacion";
import axios from "axios";
import { AuthService } from "@/services/auth.service";

export default {
  name: "GridPeticiones",
  components: {
    IonLoading,
    RowHeaderPeticiones,
    IonGrid,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonToggle,
    IonRow,
    IonLabel,
    IonItem
  },
  data() {
    return {
      c1: true,
      c2: false,
      loadingTarjeta: false,
      predSelect: null,
      nuevaTarjeta: false,
      updateTarjetasToken: null //TIMER PARA ACTUALIZAR LAS TARJETAS EN CASO DE QUE SE AÑADA UN A NUEVA VIA TOKEN 0 KINEOX
    };
  },
  setup() {
    const params = new URLSearchParams(window.location.search.substring(1));

    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })

    const recursive = useResult(result, null, (data) => data.cliente.pago_recursivo);
    const tarjetaPredeterminada = useResult(result, null, (data) => data.cliente.id_tarjeta_pago_recurrente);

    const { mutate: delTarjeta } = useMutation(tarjetaMutacion);

    //Cambiamos la tarjeta predeterminada
    //Solo puede haber una tarjeta predeterminada
    const { mutate: tarjetaPred } = useMutation(tarjetaPredeterminadaMutacion);

    let tarjetas = useResult(result, null, (data) => data.cliente.tarjetas);

    return {
      currentComponent: "Mpago",
      delTarjeta,
      error,
      chevronDown,
      chevronUp,
      recursive,
      params,
      tarjetas,
      tarjetaPredeterminada,
      moment,
      id,
      tarjetaPred,
      token,
    };
  },
  computed: {
    
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
  mounted(){    
    //COMPROBAMOS SI NOS HAN DEVUELTO EL CALLBACK
      if(this.params.get("tok")){
        this.loadingTarjeta = true
        if(this.params.get("tok") == 'ok'){     
        setTimeout(function() {
          if( window.location.href.split("?")[0]  ) window.location.href = window.location.href.split("?")[0];
        }, 4000); 
      }
    }
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    diffDate(date1: Date, date2: Date) {
      return Math.abs((new Date(date1).getTime() - new Date(date2).getTime()) / (24 * 60 * 60 * 1000));
    },
    async pagoRecursivo(el) {
      ApiService.post("/cliente/pago_auto", {
        checked: el,
        cliente: TokenService.getId(),
      }).then((res) => (this.recursive = res));
    },
    getIcon: function (name: string) {
      const images = require.context("../../src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    tokenZero() {
      axios.get("kineox/get/token/tarjeta/" + this.id).then((res) => window.location.replace(res.data))
    },
    formatDate(date) {
      if (screen.width < 700) return moment(date).format("DD/MM/YY");
      return moment(date).format("DD/MM/YYYY");
    },
    async setPredt(id) {
      //Lanzamos mutation para cambiar la tarjeta predeterminada
        this.tarjetaPred({
          'id_tarjeta': parseInt(id),
          'id_cliente': parseInt(this.id),
          'id': parseInt(this.id),
          'token': this.token
        }).then(() => {
          location.reload();
        });
    },
    async remove(id, pan) {
      const errorAlert = await alertController.create({
        header: "Atención",
        message: "Confirma que quiere eliminar la tarjeta " + pan,

        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
          },
          {
            text: "Confirmar",
            id: "confirm-button",
            handler: () => {
              this.delTarjeta({
                'id_tarjeta': parseInt(id),
                'id_cliente': parseInt(this.id),
                'id': parseInt(this.id),
                'token': this.token
              }).then(() => {
                location.reload();
              });
            },
          },
        ],
      });
      await errorAlert.present();
    },
    
  },

  watch: {
    tarjetaPredeterminada: function (val) {
      this.predSelect = val
    }
  }
};
