import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, null, {
    default: _withCtx(() => [
      ($setup.cliente)
        ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
            key: 0,
            class: "mobile mobile-footer"
          }, {
            default: _withCtx(() => [
              (!$options.currentRouterName('peticion'))
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "Peticiones",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goto((( $setup.cliente && $setup.cliente.contratos && $setup.cliente.contratos.estadov2.includes('c1') && $setup.cliente.contratos.fechabanco > '1970-01-01' ) ? '/home/peticion' : '/peticiones/historial' ))))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('peticiones')
                      }, null, 8, ["src"]),
                      ($setup.cliente && $setup.cliente.contratos && $setup.cliente.contratos.estadov2.includes('c1') && $setup.cliente.contratos.fechabanco > '1970-01-01')
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            style: {"color":"#a6c0d3"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Peticiones")
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_label, {
                            key: 1,
                            style: {"color":"#a6c0d3"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Historial")
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 1,
                    tab: "Peticiones",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goto((( $setup.cliente && $setup.cliente.contratos && $setup.cliente.contratos.estadov2.includes('c1') ) ? '/home/peticion' : '/peticiones/historial' ))))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('peticiones_active')
                      }, null, 8, ["src"]),
                      ($setup.cliente && $setup.cliente.contratos && $setup.cliente.contratos.estadov2.includes('c1') && $setup.cliente.contratos.fechabanco > '1970-01-01')
                        ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode("Peticiones")
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode("Historial")
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  })),
              (!$options.currentRouterName('pago'))
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 2,
                    tab: "Pagos",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.goto('/home/pago')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('pagos')
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, { style: {"color":"#a6c0d3"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("Pagos")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 3,
                    tab: "Pagos",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.goto('/home/pago')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('pagos_active')
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Pagos")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })),
              (!$options.currentRouterName('contacto'))
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 4,
                    tab: "Contactos",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.goto('/home/contacto')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('contacto')
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, { style: {"color":"#a6c0d3"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("Contacto")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 5,
                    tab: "Contactos",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => ($options.goto('/home/contacto')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: $options.getIcon('contacto_active')
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Contacto")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}