import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _createVNode(_component_ion_content, { "justify-content-center": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { style: {"min-width":"350px"} })
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}