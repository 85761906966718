import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenuDesktop = _resolveComponent("MainMenuDesktop")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_RowHeaderPeticiones = _resolveComponent("RowHeaderPeticiones")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ButtonSolicitudPeticion = _resolveComponent("ButtonSolicitudPeticion")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "main-grid" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "4",
            class: "no-mobile"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MainMenuDesktop)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_RowHeaderPeticiones, { title: "Revision Midas" }),
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, {
                            class: "white peticion-card",
                            style: {"height":"auto !important"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_content, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Le concertamos cita en el centro de talleres Midas más cercano a su domicilio para realizar una revisión básica a su vehículo (sin Cambios ni modificaciones), tras la que podrá comprobar el estado en el que se encuentra. ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ButtonSolicitudPeticion, {
                    alerta: "ey",
                    title: "Solicitar",
                    codigo: "6"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}