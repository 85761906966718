import gql from "graphql-tag";

export const clienteQuery: any = gql`
query cliente($id: Int,$token: String) {
  cliente(id: $id, token: $token) {
    id
    fechaalta
    estado
    sin_contrato_activo
    tipo
    usuarioalta
    usuarioactualizacion
    def_nifcif
    def_nifcifcaducidad
    def_tipo
    pago_recursivo
    def_nombre
    def_apellidos
    def_direccion
    id_tarjeta_pago_recurrente
    def_poblacion
    def_provincia
    def_cpostal
    def_email1
    def_email2
    def_telefono1
    def_telefono2
    def_telefonofijo
    def_fechanacimiento
    def_estadocivil
    def_numhijos
    def_nifcif
    def_numhijos
    def_estudios
    def_situacionlaboral
    fac_nifcif
    fac_tipo
    fac_nombre
    fac_apellidos
    fac_direccion
    fac_poblacion
    fac_provincia
    fac_cpostal
    fac_email1
    fac_email2
    fac_telefono1
    fac_telefono2
    fac_numcuenta
    fac_nombrebanco
    env_nifcif
    env_tipo
    env_nombre
    env_apellidos
    env_direccion
    env_poblacion
    env_provincia
    env_cpostal
    env_email1
    env_email2
    env_telefono1
    env_telefono2
    observaciones
    asnef_sualta
    asnef_fechaconsulta
    asnef_estado
    asnef_situacion
    asnef_fechainclusion
    asnef_productofinanciero
    asnef_importe
    administrador_area_cliente
    asnef_numero_operaciones
    asnef_ltd_fechainclusion
    asnef_ltd_cuotas
    asnef_ltd_cuota
    asnef_ltd_total
    clausula_avisolegal
    clausula_lopd
    emailvalidado
    promotor
    voro
    remember_token
    img_perfil
    pasarela_pago_preferida
    sector_empleo
    antiguedad_nomina
    sueldo_neto
    puesto_trabajo
    sector_trabajo
    empleo
    comprobacion_pbc
    tarjetas {
      id
      card_pan
      transactionId
      active
      created_at
    }
    solicitudes {
      id
      estado
      estado_general
      observaciones
      created_at
      id_tipo
    }
    acuerdos {
      estado
      cuotas {
        id
        importecuota
        importepago
        numdelacuota
        estado
        fecha
        fechapago
      }
      getProximaCuotaClientes {
        id
        importecuota
        importepago
        numdelacuota
        estado
        fecha
        fechapago
      }
    }
    contratos {
      id
      idproductos
      estado
      estadov2
      importe
      fechabanco
      coche_matricula
      coche_marca
      coche_modelo
      coche_fechaitv
      cuotas {
        id
        importecuota
        importepago
        numdelacuota
        estado
        fecha
        fechapago
        fechapagoefectiva
      }
      ultimaCuota {
        id
        importecuota
        numdelacuota
        estado
        fecha
        fechapago
	      importepago
        fecha
        fechapago
        fechapagoefectiva
        cuotainteres
        cuotacapital
        capitalpendiente
        capitalpendiente_sga
        cuotaservicios
        cuotaserviciosiva
        cuotaseguro
        cuotasegurocapital
        cuotaseguropendiente
        pagorenovacion
        gastoapertura
        pago_pendiente
        pagocancelacion
      }
      tipo_contrato {
        id
        garantia
        garantia_desempleado
        garantia_enfermedad
      }
    }
  }
}

`;
