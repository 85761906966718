
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonLoading,
} from "@ionic/vue";
import { chevronBack } from "ionicons/icons";
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import { TokenService } from "@/services/token.service";
import moment from 'moment';
import { AuthService } from "@/services/auth.service";

export default {
  name: "PagoRealizado",
  components: { IonGrid, IonRow, IonCol, IonText, IonButton, IonLoading },
  setup() {
    const router = useRouter();
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
    })

    const rutaProj = process.env.VUE_APP_ROOT_API

    const cuotas = useResult(
      result,
      null,
      (data) => data.cliente.contratos.cuotas
    );
    const acuerdos = useResult(
      result,
      null,
      (data) => data.cliente.acuerdos.cuotas
    );
    return {
      router,
      error,
      rutaProj,
      cuotas,
      acuerdos,
      chevronBack,
      loading,
      token
    };
  },
  methods: {
    checkCuotas: function () {
      this.algunaCuota = true
    },
    getCuotas: function(){
      if(this.acuerdos){
        return this.acuerdos
      } else return this.cuotas
      
    },
    formatDate(date){
      if(screen.width < 700 ) return moment(date).format("DD/MM/YY")
      return moment(date).format("DD/MM/YYYY")
    },
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
    cuotasRealizadas: function(){
      return this.getCuotas().filter(i => 
        i.importepago
      )
    }
  },
  data(){
    return{
      algunaCuota: false
    }
  },

};
