import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, KeepAlive as _KeepAlive } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonMenuList = _resolveComponent("ButtonMenuList")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return ($setup.cliente)
    ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              ($setup.TokenService.getAdmin() == 0)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    size: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonMenuList, {
                        title: "Mis Datos",
                        url: "home/cliente",
                        id: "mis-datos"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              ($setup.cliente.contratos)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (  !$setup.cliente.contratos.estadov2.includes('c4') && !$setup.cliente.contratos.estadov2.includes('c5') && !$setup.cliente.contratos.estadov2.includes('c3') && $setup.cliente.contratos.fechabanco)
                      ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ButtonMenuList, {
                              title: 'Peticiones',
                              url: "home/peticion",
                              id: "peticiones"
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_col, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ButtonMenuList, {
                              title: "Historial Peticiones",
                              url: "./peticiones/historial",
                              id: "historial"
                            })
                          ]),
                          _: 1
                        }))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonMenuList, {
                    title: "Mis Pagos",
                    url: "home/pago",
                    id: "mis-pagos"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonMenuList, {
                    title: "Contacto",
                    url: "home/contacto",
                    id: "contacto"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonMenuList, {
                    title: "Contraseña",
                    url: "home/pass",
                    id: "contrasena"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonMenuList, {
                    title: "Mis Tarjetas",
                    url: "home/mpagos",
                    id: "mis-tarjetas"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_openBlock(), _createBlock(_KeepAlive, null, [
            ($setup.TokenService.getAdmin() == 1)
              ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonMenuList, {
                          title: "Admin. Usuarios",
                          url: "home/admin"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 1024))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}