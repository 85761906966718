
import { IonContent, IonPage, IonRouterOutlet } from "@ionic/vue";
import Header from "./items/Header.vue";
import Footer from "./items/Footer.vue";
import { TokenService } from "@/services/token.service";
import { reactive } from "@vue/reactivity";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "../../graphql/queries/clienteQuery";
import { AuthService } from "@/services/auth.service";

export default {
  name: "Home",
  components: { Header, Footer, IonContent, IonRouterOutlet, IonPage },
  setup() {
    require("./css/index.css");
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "network-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
        window.location.href="/"; 
    });
    
    const cliente = useResult(result, null, (data) => data.cliente);
    return {
      loading,
      cliente,
      error,
      token
    };
  },
};
