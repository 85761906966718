import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import peticion from "../views/Main/peticion.vue";
import index from "../views/Main/index.vue";
import pago from "../views/Main/pago.vue";
import SignIn from "@/views/Login/Signin.vue";
import Signup from "@/views/Login/Signup.vue";
import Renew from "@/views/Login/Password.vue";
import Verify from "@/views/Login/Verify.vue";
import ampliacion from "@/views/Main/peticiones/ampliacion.vue";
import cambio from "@/views/Main/peticiones/cambio.vue";
import cancelacion from "@/views/Main/peticiones/cancelacion.vue";
import cancelacionp from "@/views/Main/peticiones/cancelacionp.vue";
import admin from "@/views/Main/items/admin.vue";
import carencia from "@/views/Main/peticiones/carencia.vue";
import historial from "@/views/Main/peticiones/historial.vue";
import revision from "@/views/Main/peticiones/revision.vue";
import trafico from "@/views/Main/peticiones/trafico.vue";
import contacto from "@/views/Main/contacto.vue";
import mpago from "@/views/Main/mpago.vue";
import Password from "@/views/Main/password.vue";
import Home from "@/views/Main/home.vue";
import { TokenService } from "@/services/token.service";
import { AuthService } from "@/services/auth.service";
import forgotPassword from "@/views/Login/ForgotPassword.vue";
import garantia from "@/views/Main/peticiones/garantia.vue";
import desempleado from "@/views/Main/peticiones/desempleado.vue";
import enfermedad from "@/views/Main/peticiones/enfermedad.vue";

const guard = function(to, from, next) {
  try {
    const id = to.params.id;
    const token = to.params.token;

    TokenService.saveId(id);
    TokenService.saveToken(token);

    next();
    
  } catch (error) {
    window.location.href = "/";
    
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/home/",
    component: Home,
    children: [
      {
        path: "",
        redirect: "/home/cliente",
        
      },
      {
        path: "cliente",
        component: index,
      },
      {
        path: "cliente/:id/:token",
        component: index,
        beforeEnter: (to, from, next) => {
          guard(to, from, next)
        }
      },
      {
        path: "peticion",
        component: peticion,
      },
      {
        path: "mpagos",
        component: mpago,
      },
      {
        path: "pago",
        component: pago,
      },
      {
        path: "contacto",
        component: contacto,
      },
      {
        path: "pass",
        component: Password,
      },
      {
        path: "admin",
        component: admin,
      },
    ],
  },
  {
    path: "/peticiones/",
    component: Home,
    children: [
      {
        path: "/peticiones/ampliacion",
        component: ampliacion,
      },
      {
        path: "/peticiones/cambio",
        component: cambio,
      },
      {
        path: "/peticiones/cancelacion",
        component: cancelacion,
      },
      {
        path: "/peticiones/cancelacionp",
        component: cancelacionp,
      },
      {
        path: "/peticiones/carencia",
        component: carencia,
      },
      {
        path: "/peticiones/trafico",
        component: trafico,
      },
      {
        path: "/peticiones/revision",
        component: revision,
      },
      {
        path: "/peticiones/historial",
        component: historial,
      },
      {
        path: "/peticiones/garantias",
        component: garantia,
      },
      {
        path: "/peticiones/desempleado",
        component: desempleado,
      },
      {
        path: "/peticiones/enfermedad",
        component: enfermedad,
      },
      //{ TODO: descativado hasta nuevo aviso
      //  path: "/peticiones/fallecimiento",
      //  component: fallecimiento,
      //},
      
    ],
  },

  {
    path: "/login",
    component: SignIn,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/logout",
    component: SignIn,
    meta: {
      logout: true,
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/signup",
    component: Signup,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  //cambios
  {
    path: "/forgot",
    component: forgotPassword,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/renew",
    component: Renew,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/verify",
    component: Verify,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/cliente-crm/:token",
    component: SignIn,
    meta: {
      crm: true,
    }
  },

  {
    path: '/:catchAll(.*)',
    name:'404', 
    component: SignIn
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.beforeEach((to, from, next) => {
  const caducidad = TokenService.checkToken(); //Indica si el token en cuestion ha caducado
  const isPublic = to.matched.some((record) => record.meta.public);
  const logout = to.matched.some((record) => record.meta.logout);
  const crm = to.matched.some((record) => record.meta.crm);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  //pasamos el token si viene de crm lo desencriptamos y pasamos los datos necesarios a la funciond e logearse por el crm
  const token = to.params.token;
  if(crm){
    return AuthService.signInCrm(token);
  }
  const loggedIn = !!TokenService.getToken(); 
  if(logout || (caducidad && loggedIn)){
    AuthService.signOut();
    return next({
      path: '/login'
    });
  }
  if(!loggedIn && !isPublic){
    return next({
      path: '/login'
    })
  }
  if(loggedIn && isPublic){
    return next({
      path: '/home'
    })
  }

  return next();  
});

export default router;
