
import { IonGrid, IonRow, IonCol, IonText, IonLoading, IonButton } from "@ionic/vue";
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "../../../graphql/queries/clienteQuery";
import { TokenService } from "@/services/token.service";
import ApiService from "@/services/api.service";
import moment from 'moment';
import { AuthService } from "@/services/auth.service";

export default {
  name: "PagoPendiente",
  components: { IonGrid, IonRow, IonCol, IonText, IonLoading, IonButton },
  setup() {
    const router = useRouter();
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, error, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
    })

    const espera = !setTimeout(() => {
      return false;
    }, 5000)
      ? true
      : false;

    const contrato = useResult(result, null, (data) => data.cliente.contratos.cuotas);
    const acuerdo = useResult(result, null, (data) => data.cliente.acuerdos.cuotas);

    return {
      router,
      error,
      contrato,
      acuerdo,
      espera,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
  methods: {
    abonar: function (id) {
      ApiService.get(process.env.VUE_APP_ROOT_API+"kineox/get/token/" + id + (this.acuerdo ? "/true" : "/false") + "/a").then((res) => {
        if (res.data) window.open(res.data, "_blank");
      });
    },
    getCuotas: function () {
      if (this.acuerdo) {
        this.cuotas = this.acuerdo;
      } else this.cuotas = this.contrato;
      return this.cuotas;
    },
    checkPrimero() {
      return this.abonarPrimer++;
    },
    formatDate(date){
      if(screen.width < 700 ) return moment(date).format("DD/MM/YY")
      return moment(date).format("DD/MM/YYYY")
    }
  },
  data() {
    return {
      cuotas: null,
      abonarPrimer: 1,
    };
  },
  mounted() {
    let element = document.getElementsByClassName("accion-pago")[0];
    if (element != undefined) {
      element.className += " activeButton";
      element.removeAttribute("disabled");
    }
   
  },
};
