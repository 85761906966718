
import {
  IonButton,
  IonIcon,
  IonGrid,
  IonLabel,
  IonCol,
  IonRow,
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { computed, defineComponent, reactive } from "vue";
import ButtonMenuList from "./elements/ButtonMenuList.vue";
import { TokenService } from "@/services/token.service";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import { useQuery, useResult } from "@vue/apollo-composable";
import { AuthService } from "@/services/auth.service";

export default{
  name: "MenuList",
  components: { IonGrid, IonCol, IonRow, ButtonMenuList },
  setup() {
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });
    const { result, loading, error, onResult, onError } = useQuery(
      clienteQuery,
      variables,
      {
        fetchPolicy: "cache-only",
      }
    );

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
    })

    const cliente = useResult(result, null, (data) => data.cliente);
    const estadov2 = useResult(result, null, (data) => data.cliente.contratos.estadov2);    
    return {    
      cliente,
      loading,
      error,
      estadov2,
      chevronForward,
      TokenService

    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode",
    ]),
  },
  methods: {},
};
