
import { IonPage, alertController, IonImg } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "Renew",
  components: { IonPage, IonImg },
  setup() {
    const router = useRouter();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const tokenParsed = "";
    if (token) {
      const tokenParsed = JSON.parse(decodeURI(atob(atob(token))));
    } else if (token.length == 0) window.location.href = "/login";
    const image = computed(() => require("./src/logo_bl.png"));
    const check = computed(() => require("./src/ok_check.png"));

    require("./css/login.css");
    return {
      router,
      logIn,
      personAdd,
      image,
      token,
      tokenParsed,
      check
    };
  },
  data() {
    return {
      pass: undefined,
      passR: undefined,
    };
  },
  methods: {
    ...mapActions("auth", ["mailVerificacion"]),
    CheckPass() {
      this.mailVerificacion({
        token: this.token,
      }).then((res) => {
        return res;
      });
    },
    async tokenValid(date) {
      date = new Date(date);
      const anHourAgo = Date.now() - 1000 * 60 * 60;
      if (date.getTime() < anHourAgo) {
        const errorAlert = await alertController.create({
          header: "Atención",
          message: "Token Caducado, ha pasado demasiado tiempo desde la solicitud, le recomendamos que solicite otro",

          buttons: [
            {
              text: "Cerrar",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: () => {
                location.replace("signup");
              },
            },
          ],
        });
        await errorAlert.present();
      }
    },
  },
  mounted() {
    this.tokenValid(this.tokenParsed.date);
    this.CheckPass()
  },
};
