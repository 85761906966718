
import { IonPage, IonCard, IonItem, IonLabel, alertController, IonImg, IonGrid, useIonRouter } from "@ionic/vue";
import { logIn, personAdd } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { eyeOutline, eyeOffOutline, alertCircle, mail, call } from 'ionicons/icons';
import { store } from "../../store/index";

import functions from "../Main/src/functions";

export default {
  name: "forgot",
  components: { IonPage, IonImg },
  setup() {
    const router = useRouter();
    const image = computed(() => require("./src/logo_bl.png"));
    const okImage = computed(() => require("./src/ok_crear_cuenta_movil.png"));

    require("./css/login.css");
    return {
      router,
      logIn,
      personAdd,
      image,
      eyeOutline,
      eyeOffOutline,
      alertCircle,
      mail,
      call,
      functions,
      okImage
    };
  },
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      dniError: false,
      pwdEquals: true,
      smsSelected: false,
      emailSelected: false,
      step: 1,
      form: {
        dni: '',
        method: '',
      },
      windowHeight: window.innerWidth,
      buttonDisabled: true,
      resendEnabled: true,
      timerId: null,
      remainingTime: 60,

    };
  },
  store,
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
    areInputsValid() {
      if(this.form.dni.trim() != '') return functions.validateDNI(this.form.dni.trim());
      return false
    },
  },

  methods: {
    ...mapActions("auth", ["signUp"]),
    async handleSingUp() {
      this.signUp(this.form).then(async (res) => {
        res = res.data;
        if (res.toLowerCase().includes("error")){
          this.dniError = true;
          document.getElementById("check-dni").style.border = "2px solid #fa0101";
        } else {
          this.$nextTick(() => {
            store.commit("setDataEmail", res.def_email1);
            store.commit("setDataSMS", res.def_telefono1);
            document.getElementById("check-dni").style.border = "0px";
            this.disableResendFor60Seconds();
            this.step = 2
          });
        }
      });
    },
    togglePasswordVisibility(fieldNumber) {
      if (fieldNumber === 1) {
        this.showPassword1 = !this.showPassword1;
      } else if (fieldNumber === 2) {
        this.showPassword2 = !this.showPassword2;
      }
    },
    toggleEnvioSelected(tipo) {
      if (tipo === 'email') {
        this.emailSelected = true
        this.form.method = 'mail'
        this.smsSelected = false
        this.buttonDisabled = false
      } else {
        this.emailSelected = false
        this.form.method = 'sms'
        this.smsSelected = true
        this.buttonDisabled = false
      }
    },
    getIcon: function (name: string) {
      const images = require.context("../Main/src/", false, /\.svg$/);
      return images("./" + name + ".svg");
    },
    onResize() {
      this.windowHeight = window.innerWidth
    },

    async sendVerification() {
      if (!this.resendEnabled) {
        return;
      }

      this.signUp(this.form).then(async (res) => {
        res = res.data;
        if (res.error) {
          console.error(res.error)
        } else {
          this.step = 2;
          this.disableResendFor60Seconds();
        }
      });
    },
    async disableResendFor60Seconds() {
      this.remainingTime = 60;
        if(this.resendEnabled && this.remainingTime == 60){
          this.resendEnabled = false;
          this.timerId = setInterval(() => {    
            if (this.remainingTime <= 0) {
              this.resendEnabled = true;
              clearInterval(this.timerId);
            }
            else {
              this.remainingTime--
            }
          }, 1000); 
        }
    },
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    clearInterval(this.timerId); // Clear the interval to avoid memory leaks
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
};
